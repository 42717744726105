<template>
  <div class="vue-schedule-timeline">
    <p
      v-if="typeof schedules.count !== 'undefined'"
      class="total-schedule"
      v-text="'Total Schedule Count : ' + schedules.count"
    />
    <div
      class="schedule-timeline-container"
      :style="'height: ' + ((40 * (tutors.length + 3)) + 2) + 'px;'"
    >
      <div class="timeline-group">
        <div class="group-item-spacing" />
        <div class="group-item-spacing" />
        <div class="group-item-spacing b-bottom" />
        <div
          v-for="(tutor, tutorKey) in tutors"
          :key="'tutorKey_' + tutorKey"
          class="group-item"
          :title="tutor.name"
          v-text="tutor.name"
        />
      </div>
      <div class="timeline-content">
        <timeline-header
          :dates="dates"
          :config="config"
        />
        <div
          v-for="(tutor, tutorKey) in tutors"
          :key="'tutorKey_' + tutorKey"
          class="data-row"
          :style="'width: ' + ((config.time_width * config.time.length) * dates.length) + 'px;'"
        >
          <div
            v-for="(date, datesHeaderMinuteTimeColumn) in dates"
            :key="'datesHeaderMinuteTimeColumn_' + datesHeaderMinuteTimeColumn"
            class="date-header-minute-time-column"
            :style="'width: ' + (config.time_width * config.time.length) + 'px;'"
          >
            <div
              v-for="(time, datesHeaderMinuteTimeColumnItem) in config.time"
              :key="'datesHeaderMinuteTimeColumnItem_' + datesHeaderMinuteTimeColumnItem"
              class="date-row-minute-time-column-item"
              :style="'width: ' + config.time_width + 'px;'"
            >
              <div
                v-for="(minute, datesHeaderMinuteTimeColumnTimeItem) in config.minute_group"
                :key="'datesHeaderMinuteTimeColumnTimeItem_' + datesHeaderMinuteTimeColumnTimeItem"
                class="date-header-minute-time-column-time-item"
                :style="'width: ' + ((config.time_width / config.minute_group.length) - 1) + 'px;'"
              >
                <div
                  v-if="typeof schedules.data[tutor.id + '_' + date.value + '_' + time + minute] !== 'undefined'"
                  class="event-item"
                >
                  <p
                    :title="schedules.data[tutor.id + '_' + date.value + '_' + time + minute].book_title"
                    v-text="schedules.data[tutor.id + '_' + date.value + '_' + time + minute].book_code"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimelineHeader from '@/components/TalkSchedule/TimelineHeader.vue'

export default {
  name: 'TalkScheduleTimeline',
  components: {
    TimelineHeader,
  },
  props: {
    tutors: {
      type: [Array, Object],
      required: false,
      default: null,
    },
    schedules: {
      type: [Array, Object],
      required: false,
      default: null,
    },
    dates: {
      type: [Array, Object],
      required: false,
      default: null,
    },
    config: {
      type: [Array, Object],
      required: true,
      default: null,
    },
  },
  mounted() {
    // eslint-disable-next-line
    // $('.timeline-content').css({ 'margin-left' : '-' + ($('.date-header-2021-09-08').position().left) + 'px' })
  },
}
</script>

<style>
.vue-schedule-timeline {
  position: relative;
  padding-bottom: 15px;
}
.total-schedule {
  margin-bottom: 15px;
}
.schedule-timeline-container {
  border: solid 1px #ccc;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.timeline-group {
  position: absolute;
  width: 200px;
  z-index: 10;
  background-color: #fff;
}
.b-bottom {
  border-bottom: solid 1px #ccc;
}
.group-item-spacing {
  padding: 10px;
  height: 40px;
  border-right: solid 1px #ccc;
}
.group-item {
  padding: 10px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}
.group-item:last-child {
  border-bottom: 0px;
}
.timeline-content {
  position: absolute;
  margin-left: 200px;
  width: auto;
  height: 100%;
}
.header-row {
  background-color: #f8f8f8;
  border-bottom: solid 1px #ccc;
  cursor: move;
  z-index: 1000;
}
.header-row::after {
  content: "";
  clear: both;
  display: table;
}
.date-header-item {
  height: 40px;
  padding: 10px;
  float: left;
  text-align: center;
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date-header-time-column, .date-header-time-item {
  float: left;
  text-align: center;
}

.date-header-time-item {
  height: 39px;
  line-height: 39px;
  border-right: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

.date-header-minute-time-column, .date-header-minute-time-column-time-item, .date-header-minute-time-column-item {
  float: left;
  text-align: center;
}
.header-row .date-header-minute-time-column-time-item {
  border-right: solid 1px #ccc;
  height: 40px;
  padding: 10px;
}
.data-row {
  border-bottom: solid 1px #ccc;
}
.data-row:last-child {
  border-bottom: 0px;
}
.data-row::after {
  content: "";
  clear: both;
  display: table;
}
.data-row .date-header-minute-time-column-time-item {
  height: 39px;
  padding: 10px;
}
.date-row-minute-time-column-item {
  border-right: solid 1px #ccc;
  float: left;
  text-align: center;
}
.event-item {
  background-color: #2196f3;
  color: #fff;
  width: 100%;
  display: block;
}
</style>
