<template>
  <div
    class="header-row"
    :style="'width: ' + ((config.time_width * config.time.length) * dates.length) + 'px;'"
  >
    <div
      v-for="(date, datesHeaderKey) in dates"
      :key="'datesHeaderKey_' + datesHeaderKey"
      class="date-header-item"
      :class="'date-header-' + date.value"
      :style="'width: ' + (config.time_width * config.time.length) + 'px;'"
      v-text="date.display"
    />
    <div
      v-for="(date, datesHeaderTimeItemKeyColumn) in dates"
      :key="'datesHeaderTimeItemKeyColumn_' + datesHeaderTimeItemKeyColumn"
      class="date-header-time-column"
      :style="'width: ' + (config.time_width * config.time.length) + 'px;'"
    >
      <div
        v-for="(time, datesHeaderTimeItemKeyItemColumn) in config.time"
        :key="'datesHeaderTimeItemKeyItemColumn_' + datesHeaderTimeItemKeyItemColumn"
        class="date-header-time-item"
        :style="'width: ' + config.time_width + 'px;'"
        v-text="time + ':00'"
      />
    </div>
    <div
      v-for="(date, datesHeaderMinuteTimeColumn) in dates"
      :key="'datesHeaderMinuteTimeColumn_' + datesHeaderMinuteTimeColumn"
      class="date-header-minute-time-column"
      :style="'width: ' + (config.time_width * config.time.length) + 'px;'"
    >
      <div
        v-for="(time, datesHeaderMinuteTimeColumnItem) in config.time"
        :key="'datesHeaderMinuteTimeColumnItem_' + datesHeaderMinuteTimeColumnItem"
        class="date-header-minute-time-column-item"
        :style="'width: ' + config.time_width + 'px;'"
      >
        <div
          v-for="(minute, datesHeaderMinuteTimeColumnTimeItem) in config.minute"
          :key="'datesHeaderMinuteTimeColumnTimeItem_' + datesHeaderMinuteTimeColumnTimeItem"
          class="date-header-minute-time-column-time-item"
          :style="'width: ' + (config.time_width / config.minute.length) + 'px;'"
          v-text="minute"
        />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TalkScheduleTimelineHeader',
  props: {
    dates: {
      type: [Array, Object],
      required: false,
      default: null,
    },
    config: {
      type: [Array, Object],
      required: true,
    },
  },
  mounted() {
    // eslint-disable-next-line
    // $('.timeline-content').css({ 'margin-left' : '-' + ($('.date-header-2021-09-08').position().left) + 'px' })
  },
}
</script>

<style scoped>
</style>
