import axios from '@/libs/axios'

class Talk {
  static today() {
    return axios.post('admin/talk/schedule/today')
  }

  static week() {
    return axios.post('admin/talk/schedule/week')
  }
}

export default Talk
